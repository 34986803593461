import React from "react";
import { motion } from "framer-motion";
import openingImage from "../images/seatingoutdoor.png";

const LandingPage = () => {
  return (
    <>
      {/* Hero Section */}
      <div className="flex flex-col md:flex-row h-[80vh] font-avenir md:h-screen">
        {/* Left Side - Image */}
        <div
          className="w-full md:w-1/2 h-64 md:h-full bg-cover rounded bg-center"
          style={{
            backgroundImage: `url(${openingImage})`,
          }}
        ></div>

        {/* Right Side - Text */}
        <div className="w-full md:w-1/2 flex flex-col justify-center px-8 md:px-16 py-12 bg-white">
          {/* Headline */}
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-3xl md:text-5xl font-bold text-red-900 leading-tight"
          >
            Designing Dreams, Preserving The Earth.
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            className="text-lg md:text-xl mt-8 text-gray-600"
          >
            Transform your spaces with timeless creativity and design. Start
            your journey with{" "}
            <span className="text-red-900 font-extrabold"> Kala Kalpa </span>
            today!
          </motion.p>

          {/* Call-to-action buttons */}
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            className="mt-12 flex space-x-4"
          >
            <a href="/porfolio" className="inline-block">
              <button className="bg-red-900 text-white px-6 py-3 rounded-md text-lg font-medium hover:bg-red-800 transition">
                Work
              </button>
            </a>
            <a href="/contact-us" className="inline-block">
              <button className="border border-red-900 text-red-900 px-6 py-3 rounded-md text-lg font-medium hover:bg-red-100 transition">
                Reach us out
              </button>
            </a>
          </motion.div>
        </div>
      </div>

      {/* Additional Section */}
      <section className="bg-white text-black">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="max-w-screen-md mx-auto text-center">
            <motion.h2
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
              className="mb-4 text-3xl md:text-4xl tracking-tight font-extrabold text-black"
            >
              We design Nature-Inspired, Luxurious Spaces
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="text-black text-lg sm:text-xl mt-6"
            >
              <span className="block">Crafted with care for the planet,</span>
              <span className="block">
                Blending comfort, beauty, and sustainability.
              </span>
              <span className="block">Bringing joy, nature, and serenity</span>
              <span className="block text-red-900 text-2xl mt-4 font-extrabold">
                Together __
              </span>
            </motion.p>
          </div>
        </div>
      </section>
    </>
  );
};

export default LandingPage;
