import React from "react";
import { motion } from "framer-motion";
import imageview from "../images/countrystyleverandah1.png";

const AboutUs = () => {
  return (
    <section className="bg-white text-gray-800 py-16">
      <div className="container mx-auto flex flex-col-reverse lg:flex-row items-center">
        {/* Image Section */}
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="w-full lg:w-1/2 h-64 lg:h-[500px] bg-cover bg-center"
          style={{
            backgroundImage: `url(${imageview})`,
          }}
        ></motion.div>

        {/* Text Section */}
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="w-full lg:w-1/2 px-6 lg:px-12"
        >
          <h2 className="text-4xl font-extrabold text-red-900">About Us</h2>
          <p className="mt-6 text-lg leading-relaxed text-gray-700">
            Kala Kalpa combines two significant concepts: "Kala," which stands
            for art, creativity, and timeless beauty, and "Kalpa," which
            represents transformation, renewal, and long periods of time in
            Indian philosophy.
          </p>
          <p className="mt-4 mb-4 text-lg leading-relaxed text-gray-700">
            Together, Kala Kalpa captures the essence of enduring creativity and
            transformative design, where art and innovation come together to
            create lasting and meaningful spaces.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default AboutUs;
