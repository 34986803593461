import React from "react";
import { CssBaseline, Typography, Container, Link } from "@mui/material";

const Footer = () => {
  const Copyright = () => {
    return (
      <Typography variant="body2" color="textSecondary">
        {"Copyright © "}
        <Link color="inherit" href="/">
          Kala Kalpa
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  };

  return (
    <>
      <div style={{ margin: 1, padding: 2, marginBottom: 2 }}>
        <CssBaseline />

        <Container
          maxWidth="sm"
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "center",
          }}
        >
          <Copyright />
        </Container>
      </div>
    </>
  );
};

export default Footer;
