import React from "react";
import ParallexEffect from "./ParallexEffect";
import ShuffleHero from "./ShuffleHero";
import { TextParallaxContentExample } from "./TextParallaxContentExample";
import Services from "./Services";
import Contact from "./Contact";
import AboutUs from "./AboutUs";
import ".././App.css";
import LandingPage from "./LandingPage";
import { motion } from "framer-motion";

export const HeroSection = () => {
  return (
    <>
      <LandingPage></LandingPage>
      <ParallexEffect></ParallexEffect>
      <section>
        <Services />
      </section>
      <section className="bg-white text-black py-16">
        <div className="px-6 mx-auto max-w-screen-md text-center">
          {/* Animated Heading */}
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="mb-6 text-3xl md:text-4xl font-extrabold text-black"
          >
            We don't just build homes
          </motion.h2>

          {/* Animated Paragraph */}
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="text-lg md:text-xl text-gray-700 leading-relaxed"
          >
            We build spaces that live and breathe with their environment,
            inspired by nature and designed for luxury. At{" "}
            <span className="text-red-900 font-extrabold">Kala Kalpa</span>,
            every project is a perfect blend of beauty, innovation, and
            responsibility.
          </motion.p>
        </div>
      </section>

      <section>
        <TextParallaxContentExample />
      </section>
      <section>
        <ShuffleHero />
      </section>

      <section>
        <AboutUs />
      </section>
      <section>
        <Contact />
      </section>
    </>
  );
};
