import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

// Import all images
import bedroomStudio1 from "../images/bedroomstudio1.png";
// Include other image imports as needed...
import bedroomStudio2 from "../images/bedroomstudiomezzanine.png";
import compactHousing from "../images/compacthousing.jpg";
import countryStyle1 from "../images/countrystyleverandah1.png";
import countryStyle2 from "../images/countrystyleverandah2.png";
import earthbagDome from "../images/earthbagdome.jpg";
import earthbagShelter1 from "../images/earthbagshelter1.jpg";
import earthbagShelter2 from "../images/earthbagshelter.jpg";
import gabionWall from "../images/gabionwall.jpg";
import kitchenIsland from "../images/kitchenisland.jpg";
import livingSpace2 from "../images/livingspace2.png";
import livingSpaceDouble from "../images/livingspacedoubleheight.png";
import luxuriousHome from "../images/luxurioushomelevation.jpg";
import mainImage1 from "../images/mainImage1.jpg";
import mainView from "../images/mainview.jpg";
import mexicanDome from "../images/mexicandome.jpg";
import mexicanSilhouette from "../images/mexicandomesilhoutte.jpg";
import nischeWall from "../images/nischewall.jpg";
import psShelter1 from "../images/pshshelter1.jpg";
import psShelter2 from "../images/pshshelter2.jpg";
import rampEarth from "../images/ramprammedearth.jpg";
import readingLounge from "../images/readinglounge.jpg";
import render1 from "../images/render1.jpg";
import render2 from "../images/render2.jpg";
import render3 from "../images/render3.jpg";
import render4 from "../images/render4.jpg";
import seatingOutdoor from "../images/seatingoutdoor.png";
import stairs from "../images/stairs.png";
import stoneMasonry from "../images/stonemasonrydetail.jpg";
import toilet from "../images/toilet.png";
import toiletSpace from "../images/toiletspace.jpg";
import toiletWash from "../images/toiletwashbasincabinet.png";
import urbanCourtyard from "../images/urbancourtyard.png";
import verandah3 from "../images/verandah3.png";
import verandah4 from "../images/verandah4.png";

// Lazy image component with motion effects
const MotionImage = ({ src, alt, className, aspectRatio }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (inView) {
      setImageSrc(src);
    }
  }, [src, inView]);

  return (
    <motion.div
      ref={ref}
      className={`relative overflow-hidden rounded-lg shadow-md ${className}`}
      style={{ paddingBottom: `${aspectRatio}%` }}
      initial={{ opacity: 0, rotate: -5 }}
      animate={inView ? { opacity: 1, rotate: 0 } : {}}
      transition={{ duration: 0.6, ease: "easeOut" }}
      whileHover={{ scale: 1.05, rotate: 3 }}
    >
      {imageSrc ? (
        <img
          src={imageSrc}
          alt={alt}
          className="absolute top-0 left-0 w-full h-full object-cover"
          loading="lazy"
        />
      ) : (
        <div className="bg-gray-200 animate-pulse w-full h-full absolute"></div>
      )}
    </motion.div>
  );
};

// Main portfolio component
export const Porfolio = () => {
  const images = [
    { src: bedroomStudio1, alt: "Bedroom Studio 1" },
    // Add other images here...
    { src: bedroomStudio2, alt: "Bedroom Studio 2" },
    { src: compactHousing, alt: "Compact Housing" },
    { src: countryStyle1, alt: "Country Style Verandah 1" },
    { src: countryStyle2, alt: "Country Style Verandah 2" },
    { src: earthbagDome, alt: "Earthbag Dome" },
    { src: earthbagShelter1, alt: "Earthbag Shelter 1" },
    { src: earthbagShelter2, alt: "Earthbag Shelter 2" },
    { src: gabionWall, alt: "Gabion Wall" },
    { src: kitchenIsland, alt: "Kitchen Island" },
    { src: livingSpace2, alt: "Living Space 2" },
    { src: livingSpaceDouble, alt: "Living Space Double Height" },
    { src: luxuriousHome, alt: "Luxurious Home Elevation" },
    { src: mainImage1, alt: "Main Image 1" },
    { src: mainView, alt: "Main View" },
    { src: mexicanDome, alt: "Mexican Dome" },
    { src: mexicanSilhouette, alt: "Mexican Dome Silhouette" },
    { src: nischeWall, alt: "Nische Wall" },
    { src: psShelter1, alt: "PSH Shelter 1" },
    { src: psShelter2, alt: "PSH Shelter 2" },
    { src: rampEarth, alt: "Rammed Earth" },
    { src: readingLounge, alt: "Reading Lounge" },
    { src: render1, alt: "Render 1" },
    { src: render2, alt: "Render 2" },
    { src: render3, alt: "Render 3" },
    { src: render4, alt: "Render 4" },
    { src: seatingOutdoor, alt: "Seating Outdoor" },
    { src: stairs, alt: "Stairs" },
    { src: stoneMasonry, alt: "Stone Masonry Detail" },
    { src: toilet, alt: "Toilet" },
    { src: toiletSpace, alt: "Toilet Space" },
    { src: toiletWash, alt: "Toilet Washbasin Cabinet" },
    { src: urbanCourtyard, alt: "Urban Courtyard" },
    { src: verandah3, alt: "Verandah 3" },
    { src: verandah4, alt: "Verandah 4" },
  ];

  const punchLines = [
    "Crafting the future, one sustainable masterpiece at a time.",
    "Luxury redefined: Where aesthetics embrace the planet.",
    "Designing dreams, preserving the earth.",
    "Timeless architecture, rooted in sustainability.",
    "Building spaces that breathe with nature.",
    "Where elegance meets eco-consciousness.",
    "Redefining luxury through sustainable innovation.",
    "Green design, golden standards.",
    "Sustainable beauty, designed to inspire.",
    "Architecting harmony, one space at a time.",
    // Add more punchlines as needed...
  ];

  return (
    <div className="px-6 py-10 mx-auto max-w-screen-xl md:px-12 lg:px-16 lg:py-20">
      <div className="flex flex-col w-full mb-10 lg:justify-between lg:flex-row md:mb-12">
        <motion.div
          className="flex items-center mb-6 md:mb-4 group lg:max-w-xl"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <h2 className="font-sans text-4xl font-bold leading-none tracking-tight text-gray-900 sm:text-5xl">
            <span className="inline-block mb-2">Our Work</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-red-900 scale-x-30 group-hover:scale-x-100" />
          </h2>
        </motion.div>
        <motion.p
          className="w-full text-gray-700 lg:text-base lg:max-w-md"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
        >
          Explore our portfolio showcasing the beauty and uniqueness of our work
          across different projects.
        </motion.p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {images.map(({ src, alt }, index) => (
          <React.Fragment key={index}>
            <MotionImage
              src={src}
              alt={alt}
              className="" // Remove col-span and row-span logic to avoid gaps
              aspectRatio={66.6} // Adjust as needed
            />
            {(index + 1) % 4 === 0 && (
              <motion.div
                className="flex items-center justify-center text-center bg-white rounded"
                style={{ height: "100%" }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, ease: "easeOut" }}
              >
                <p className="text-3xl font-bold text-red-900">
                  {punchLines[index % punchLines.length]}
                </p>
              </motion.div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Porfolio;
