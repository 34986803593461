import React from "react";
import { motion } from "framer-motion";

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6, delay: 0.2 } },
  hover: {
    scale: 1.05,
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
    rotateX: 3,
    rotateY: 3,
    transition: { duration: 0.4 },
  },
};

const Services = () => {
  const services = [
    {
      title: "Design & Build",
      description:
        "Concept to creation, custom-built spaces, and a seamless blend of innovation and functionality.",
    },
    {
      title: "Architecture & Interiors",
      description:
        "Innovative architectural solutions paired with bespoke interior design for optimized spaces.",
    },
    {
      title: "Project Management Consultancy",
      description:
        "Efficient oversight ensuring projects are delivered on time, within budget, and with assured quality.",
    },
    {
      title: "Land & Community Design",
      description:
        "Creating eco-friendly, functional, and sustainable landscapes for communities and individuals.",
    },
  ];

  return (
    <div className="bg-white min-h-screen flex flex-col justify-center px-6 py-16 sm:px-10 md:px-16 lg:px-24">
      {/* Heading Section */}
      <motion.div
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-center mb-12"
      >
        <h2 className="text-4xl md:text-5xl font-extrabold text-red-900">
          Our Services
        </h2>
        <p className="text-lg md:text-xl text-gray-600 mt-4">
          Discover how Kala Kalpa turns ideas into elegant and functional
          spaces.
        </p>
      </motion.div>

      {/* Services Grid */}
      <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
        {services.map((service, index) => (
          <motion.div
            key={index}
            className="relative bg-white p-6 rounded-xl shadow-md border border-gray-200 hover:scale-105 transition-transform duration-300"
            variants={cardVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            whileHover="hover"
          >
            {/* Card Border Effect */}
            <div className="absolute inset-0 rounded-xl border-2 border-transparent bg-gradient-to-r from-red-100 to-red-300 opacity-0 hover:opacity-100 transition-opacity duration-300"></div>
            {/* Card Content */}
            <div className="relative z-10">
              <h3 className="text-xl font-bold text-red-900 mb-4">
                {service.title}
              </h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Services;
