import React, { useState } from "react";
import logo from "../kalakalpa.png";

const pages = [
  { id: 1, title: "Studio", url: "/" },
  { id: 2, title: "Portfolio", url: "porfolio" },
  // { id: 3, title: "Contact", url: "contact-us" },
];

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="fixed top-0 left-0 w-full z-50 bg-opacity-60 backdrop-blur-lg backdrop-saturate-150 bg-white/30 shadow-md">
      <div className="container mx-auto flex items-center justify-between h-16 px-4 md:px-8">
        {/* Logo */}
        <a href="/" className="flex items-center">
          <img className="h-10" src={logo} alt="logo" />
          <span className="ml-4 text-lg md:text-xl uppercase font-bold text-gray-900">
            KALA KALPA
          </span>
        </a>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-8 font-semibold text-base lg:text-lg">
          {pages.map((page) => (
            <a
              key={page.id}
              href={page.url}
              className="hover:text-gray-700 transition text-gray-900"
            >
              {page.title}
            </a>
          ))}
        </nav>

        {/* Contact Button */}
        <a
          href="/contact-us"
          className="hidden md:block border border-white rounded-full font-bold px-6 py-2 hover:bg-red-900 hover:border-red-900 hover:text-white transition text-gray-900"
        >
          Contact Us
        </a>

        {/* Mobile Hamburger Menu */}
        <button
          className="md:hidden flex items-center justify-center p-2 rounded focus:outline-none focus:ring-2 focus:ring-gray-400"
          onClick={toggleMenu}
        >
          <span className="sr-only">Open menu</span>
          <svg
            className="w-6 h-6 text-gray-900"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d={
                isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"
              }
            />
          </svg>
        </button>
      </div>

      {/* Mobile Dropdown Menu */}
      {isMenuOpen && (
        <div className="md:hidden bg-white/60 backdrop-blur-lg backdrop-saturate-150 shadow-lg">
          <nav className="flex flex-col space-y-2 px-4 py-3">
            {pages.map((page) => (
              <a
                key={page.id}
                href={page.url}
                className="block text-base font-semibold text-gray-900 hover:bg-gray-200 rounded px-2 py-1 transition"
                onClick={() => setIsMenuOpen(false)}
              >
                {page.title}
              </a>
            ))}
            <a
              href="/contact-us"
              className="block text-center text-base font-semibold border border-gray-900 rounded-full px-6 py-2 hover:bg-gray-200 transition text-gray-900"
              onClick={() => setIsMenuOpen(false)}
            >
              Contact Us
            </a>
          </nav>
        </div>
      )}
    </header>
  );
}

export default Navbar;
