import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import myVideo from "../fireplace.mp4";
import conVid from "../birdsv.mp4";
import startv from "../startv.mp4";
import endvie from "../endv.mp4";

export const TextParallaxContentExample = () => {
  return (
    <div className="bg-white">
      <TextParallaxContent
        imgUrl={startv}
        subheading="Feel"
        heading="Inspire, Connect, Elevate."
      >
        <ExampleContent
          heading="Elevate Living. Embrace Nature."
          para1="Spaces that go beyond functionality and aesthetics, forging deep connections with both nature and the communities they serve."
          para2="Our mission is to design homes and buildings that inspire, uplift, and leave a lasting, meaningful impact."
        />
      </TextParallaxContent>

      <TextParallaxContent
        imgUrl={myVideo}
        subheading="Design"
        heading="Elegant, Efficient and Forward-thinking."
      >
        <ExampleContent
          heading="Innovation Design. Energy Efficiency."
          para1="We blend timeless elegance with innovative efficiency, creating spaces that are not only visually captivating but also deeply eco-conscious."
          para2="By integrating advanced alternative techniques, we optimize energy performance while maintaining a visionary aesthetic, ensuring your home is as intelligent as it is refined."
        />
      </TextParallaxContent>
      <TextParallaxContent
        imgUrl={conVid}
        subheading="Art"
        heading="Natural, Precise, Timeless."
      >
        <ExampleContent
          heading="Natural Materials. Skilled Artisans."
          para1="Our dedication to natural materials and collaboration with skilled artisans ensures that every element is meticulously crafted with precision and care."
          para2="This approach results in a timeless quality that celebrates traditional craftsmanship while seamlessly integrating modern sustainability, creating homes that are both enduring and in harmony with the environment."
        />
      </TextParallaxContent>
      <TextParallaxContent
        imgUrl={endvie}
        subheading="Quality"
        heading="Ergonomic, Sustainable, Enduring."
      >
        <ExampleContent
          heading="Human-Centric. Lasting Quality."
          para1="We design spaces with your comfort and well-being at the forefront, ensuring an intuitive, effortless living experience."
          para2="Our commitment to sustainability and enduring quality means that every material and method is chosen for its environmental impact and durability, crafting homes that last and truly enhance your life."
        />
      </TextParallaxContent>
    </div>
  );
};

const IMG_PADDING = 8;

const TextParallaxContent = ({ imgUrl, subheading, heading, children }) => {
  return (
    <div
      style={{
        paddingLeft: IMG_PADDING,
        paddingRight: IMG_PADDING,
      }}
    >
      <div className="relative h-[150vh] md:h-[100vh] sm:h-[80vh]">
        <StickyImage imgUrl={imgUrl} />
        <OverlayCopy heading={heading} subheading={subheading} />
      </div>
      {children}
    </div>
  );
};

const StickyImage = ({ imgUrl }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

  return (
    <motion.div
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: `calc(100vh - ${IMG_PADDING * 2}px - 3.5rem)`,
        top: IMG_PADDING,
        scale,
      }}
      ref={targetRef}
      className="sticky overflow-hidden rounded-3xl"
    >
      <video
        className="w-full h-full object-cover"
        autoPlay
        muted
        loop
        playsInline
      >
        <source src={imgUrl} type="video/mp4" />
      </video>

      <motion.div
        className="absolute inset-0 bg-neutral-950/70"
        style={{
          opacity,
        }}
      />
    </motion.div>
  );
};

const OverlayCopy = ({ subheading, heading }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [200, -200]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <motion.div
      style={{
        y,
        opacity,
      }}
      ref={targetRef}
      className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white"
    >
      <p className="mb-2 text-center text-lg md:text-xl">{subheading}</p>
      <p className="text-center text-2xl md:text-5xl font-bold">{heading}</p>
    </motion.div>
  );
};

const ExampleContent = ({ heading, para1, para2 }) => {
  return (
    <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-12 pt-8 md:grid-cols-12">
      <h2 className="col-span-1 text-xl font-bold md:col-span-4 md:text-3xl">
        {heading}
      </h2>
      <div className="col-span-1 md:col-span-8">
        <p className="mb-4 text-base text-neutral-600 md:text-xl">{para1}</p>
        <p className="mb-8 text-base text-neutral-600 md:text-xl">{para2}</p>
      </div>
    </div>
  );
};
