import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Snackbar, Alert } from "@mui/material";
import { motion } from "framer-motion";
import { Canvas } from "@react-three/fiber";
import { Environment, MeshDistortMaterial, Sphere } from "@react-three/drei";

const ContactPage = () => {
  const [fullname, setFullname] = useState("");
  const [reason, setReason] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const form = useRef();

  const serviceId = "service_jy3v4dp";
  const templateId = "template_yf6hnsm";
  const publicKey = "W7tZI3BR0pzpOrmej";

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: fullname,
      reason: reason,
      from_email: email,
      phone: phone,
      message: message,
      reply_to: email,
    };

    emailjs.send(serviceId, templateId, templateParams, publicKey).then(
      () => {
        setSnackbarMessage("Email sent successfully!");
        setSnackbarOpen(true);
      },
      (error) => {
        setSnackbarMessage("Failed to send email. Try again later.");
        setSnackbarOpen(true);
      }
    );
  };

  return (
    <div className="relative my-4 w-full h-screen flex items-center justify-center bg-white">
      {/* Flowing Background Animation (Hidden on Mobile) */}
      <div className="hidden lg:block absolute inset-0 w-full h-full z-0">
        <Canvas>
          <ambientLight intensity={0.4} />
          <pointLight position={[10, 10, 10]} />
          <Sphere visible args={[1, 100, 200]} scale={2.86}>
            <MeshDistortMaterial
              color="#7f1d1d"
              attach="material"
              distort={0.57} // Increased distortion by 10%
              speed={1.5}
            />
          </Sphere>
          <Environment preset="warehouse" />
        </Canvas>
      </div>

      {/* Contact Form and Office Details */}
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 0.9, scale: 1 }}
        transition={{ duration: 1 }}
        className="relative mt-4 z-10 w-full max-w-3xl bg-white/80 p-8 md:p-12 rounded-xl shadow-lg backdrop-blur-sm flex flex-col items-center"
      >
        {/* Form */}
        <h2 className="text-4xl font-bold text-red-900 mb-12 mt-16 text-center md:mt-20">
          Contact Us
        </h2>
        <form ref={form} onSubmit={sendEmail} className="w-full">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <input
              className="w-full p-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-red-900 focus:outline-none"
              type="text"
              name="fullname"
              placeholder="Full Name*"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              required
            />
            <select
              className="w-full p-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-red-900 focus:outline-none"
              name="reason"
              defaultValue={reason}
              onChange={(e) => setReason(e.target.value)}
              required
            >
              <option value="">-- Select Reason --</option>
              <option value="I want to design my dream home.">
                I want to design my dream home.
              </option>
              <option value="I need help with my commercial space.">
                I need help with my commercial space.
              </option>
              <option value="Looking for stunning interiors.">
                Looking for stunning interiors.
              </option>
              <option value="I’m planning a renovation or upgrade.">
                I’m planning a renovation or upgrade.
              </option>
              <option value="I have land and need a design plan.">
                I have land and need a design plan.
              </option>
              <option value="I don’t know what I need, but I know I need you!">
                I don’t know what I need, but I know I need you!
              </option>
            </select>
            <input
              className="w-full p-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-red-900 focus:outline-none"
              type="email"
              name="email"
              placeholder="Email*"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              className="w-full p-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-red-900 focus:outline-none"
              type="number"
              name="phone"
              placeholder="Phone Number*"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <textarea
            className="w-full mt-6 p-3 h-32 rounded-lg border border-gray-300 focus:ring-2 focus:ring-red-900 focus:outline-none"
            name="message"
            placeholder="Your Message*"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
          <button
            type="submit"
            className="w-full bg-red-900 text-white font-bold py-3 px-6 mt-6 rounded-lg hover:bg-red-700 transition duration-300"
          >
            Send Message
          </button>
        </form>

        {/* Office Details */}
        <div className="mt-8">
          <h2 className="text-2xl font-bold text-red-900 mb-4 text-center">
            Office Details
          </h2>
          <p className="text-gray-600 text-center">
            C, 15/1, 2nd Crescent Road, Sainikpuri, Hyderabad, Secunderabad,
            Telangana 500076
          </p>
          <p className="mt-4 text-gray-600 text-center">
            Phone: +91 8074539321
          </p>
          <p className="text-gray-600 text-center">Email: info@kalakalpa.com</p>
        </div>
      </motion.div>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={
            snackbarMessage.includes("successfully") ? "success" : "error"
          }
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContactPage;
