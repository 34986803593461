import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

const ParallexEffect = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });

  // Motion values for parallax effects
  const backgroundY = useTransform(scrollYProgress, [0, 1], ["0%", "50%"]);
  const headingY = useTransform(scrollYProgress, [0, 1], ["0%", "-100%"]);
  const headingOpacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);

  // Framer Motion Variants for Bounce-In Animation
  const bounceInVariant = {
    hidden: { opacity: 0, y: -50, scale: 0.8 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 500,
        damping: 25,
        duration: 0.5,
        delay: 0.2,
      },
    },
  };

  return (
    <div
      ref={ref}
      className="relative w-full h-screen overflow-hidden flex items-center bg-gray-900"
    >
      {/* Background Parallax */}
      <motion.div
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: `url(${require("../heroImage.png")})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          y: backgroundY,
        }}
      />

      {/* Overlay for additional background effect */}
      <div
        className="absolute inset-0 z-0 bg-black/40"
        style={{
          backgroundImage: `url(${require("../heroCut.png")})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />

      {/* Heading with Entry Animation and Parallax */}
      <motion.div
        style={{
          y: headingY,
          opacity: headingOpacity,
        }}
        initial="hidden"
        animate="visible"
        className="relative z-10 text-white font-bold px-6 sm:px-4 text-left"
      >
        <motion.h1
          className="text-3xl sm:text-2xl md:text-4xl lg:text-5xl leading-tight"
          variants={bounceInVariant}
        >
          Where elegance
        </motion.h1>
        <motion.h1
          className="text-5xl sm:text-4xl md:text-6xl lg:text-7xl leading-tight text-red-900"
          variants={bounceInVariant}
        >
          meets
        </motion.h1>
        <motion.h1
          className="text-3xl sm:text-2xl md:text-4xl lg:text-5xl leading-tight"
          variants={bounceInVariant}
        >
          eco-consciousness.
        </motion.h1>
      </motion.div>
    </div>
  );
};

export default ParallexEffect;
